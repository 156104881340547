@function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
}

:root {
    --color-primary: #16498a;
}

:root {
    --text-color: #f3f3f3;
    --background: #21282d;
    --background-rgba: #{hexToRGB(#21282d)};
    --header-color: #e784a2;
    --link-color-darker: #ef4276;
    --highlight-color: #85b5c7;
    --base-padding: 10px;

    --active-timer-color: #66cdaa;
    --active-timer-color-rgb: #{hexToRGB(#66cdaa)};
    --stopped-timer-color: #e784a2;
    --stopped-timer-color-rgb: #{hexToRGB(#e784a2)};
}

.ladies-night-theme {
    --background: #21282d;
    --header-color: #e784a2;
    --link-color-darker: #ef4276;
    --highlight-color: #85b5c7;

    --active-timer-color: #66cdaa;
    --active-timer-color-rgb: #{hexToRGB(#66cdaa)};
    --stopped-timer-color: #e784a2;
    --stopped-timer-color-rgb: #{hexToRGB(#e784a2)};
}

.sublime-theme {
    --background: #cfbfad;
    --header-color: #ff007f;
    --link-color-darker: #ef4276;
    --highlight-color: #52e3f6;

    --active-timer-color: #a7ec21;
    --active-timer-color-rgb: #{hexToRGB(#a7ec21)};
    --stopped-timer-color: #ff007f;
    --stopped-timer-color-rgb: #{hexToRGB(#ff007f)};
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--background);
}

#bg-image,
#bg-image img {
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    z-index: -10;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('./assets/images/bg-image.jpg');
    filter: grayscale(80%);
    transition: filter 2s linear, background 4s ease-in-out;
}

#bg-image {
    &.active::after {
        background: rgba(var(--active-timer-color-rgb), 0.2);
        animation: none;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        background: rgba(var(--stopped-timer-color-rgb), 0.6);
        width: 100%;
        height: 100%;
        z-index: -5;
        animation: fade-in-out 5s infinite;
        transition: all 1s;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
}

@keyframes fade-in-out {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
