.sound-selector {
    .sound-selector__play {
        display: inline-block;
        margin-left: var(--base-padding);

        &:hover {
            cursor: pointer;
        }
    }
}
