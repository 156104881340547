.Users {
    $addUserSize: 180px;
    padding: $addUserSize 0 $addUserSize/2;
    border-radius: 3px;

    .draggable-users {
        padding: 0 10px;
        margin-left: $addUserSize;
        align-items: center;
        overflow: visible !important;
    }

    .user__add {
        color: yellow;
    }

    .users__footer {
        margin-top: 20px;
    }
}
