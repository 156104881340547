@import './../Global.scss';

$transitionTime: 0.2s;
$activeColor: #66cdaa;
$inactiveColor: #d7686c;
$pausedColor: #d7d283;

.Timer {
    margin: 40px 0;

    .timer {
        box-sizing: content-box;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .timer__time {
            @extend .text-shadow;
            user-select: none;
            color: #f9f9f9;
            position: absolute;
            font-size: 50px;
            font-weight: bold;
        }

        .paused,
        .inactive,
        .active {
            transition: stroke $transitionTime ease-in-out;
        }

        .timer__time--numbers {
            display: block;
        }

        .timer__time--icons {
            display: none;
        }

        &:hover {
            .timer__time--numbers {
                display: none;
            }

            .timer__time--icons {
                display: block;
                -webkit-filter: drop-shadow(2px 2px 2px #000);
                filter: drop-shadow(2px 2px 2px #000);
            }
        }
    }
}
