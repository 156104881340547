@import 'Global.scss';

html,
body {
    background: #20002c; /* fallback for old browsers */
    background: sticky var(--background);
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
}

input {
    padding: 4px;
}

.app {
    color: var(--text-color);
    text-align: center;
    min-height: 100vh;
    flex-direction: column;

    .unsplashed-credits {
        margin-right: 6px;
        padding: 4px 8px;
        font-size: 0.7em;
        color: #666;
        bottom: 80px;
        text-align: right;
        right: 0;

        a {
            color: #888;
            text-decoration: underline;
        }
    }
}

h1,
h2,
h3,
h4 {
    color: var(--header-color);
    font-weight: bold;
}

.hide {
    text-align: left;
    background: #222;
    color: #888;
    font-size: 11px;
    width: 90%;
    z-index: 999999999;
    position: sticky;
}

.pointer {
    cursor: pointer;
}

footer {
    @extend .text-shadow;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: stretch;
    flex-flow: row nowrap;
    background: rgba(0, 0, 0, 0.9);
    border-width: 2px 0 0 0;
    border-style: solid;
    border-color: #000000;
    align-items: flex-start;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    padding: var(--base-padding);

    .footer__item {
        flex: 1;
        text-align: left;
        border: solid #000000;
        border-width: 0 1px 0 1px;
        user-select: none;

        h4,
        p,
        ol {
            padding: 2px;
            margin: 0;
        }

        ol {
            margin-left: 20px;
        }
    }

    .center {
        text-align: center;
    }
}
