.theme-button {
    display: inline-block;
    margin: 2px;

    .theme-button__button {
        &:hover {
            cursor: help;
        }
    }
}
