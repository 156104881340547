@keyframes fadeInImg {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.img {
    user-select: none;

    &.loading {
        opacity: 0;
        width: 100%;
        height: auto;
    }

    &.loaded {
        animation: fadeInImg ease-in-out;
        position: relative;
        opacity: 0;
        animation-fill-mode: forwards;
        animation-duration: 2s;
    }
}
