@import './../Global.scss';

$imageSize: 80px;
$userSpacing: 19px;
$borderSize: 3px;

.user {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    margin: 0 $userSpacing;

    figure {
        margin: 0;
    }

    &:hover {
        .user__remove {
            visibility: visible;

            svg {
                cursor: pointer;
            }
        }

        .user__crown {
            transform: rotate(0deg);
            left: 0;
        }
    }

    .user__crown {
        background: url(../assets/images/crown.png) no-repeat;
        background-size: cover;
        height: 50px;
        width: $imageSize + 10;
        top: -32px;
        left: -$userSpacing;
        transform: rotate(-20deg);
        position: absolute;
        z-index: 2;
        transition: all 0.5s;
    }

    .user__image {
        position: relative; // important for .shine to not overflow.
        border-radius: 50%;
        width: $imageSize;
        height: $imageSize;
        text-indent: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border: $borderSize solid var(--stopped-timer-color);
        overflow: hidden;

        &.active {
            border: $borderSize solid var(--active-timer-color);
        }

        &.disabled {
            filter: blur(1px);
            opacity: 0.4;
        }
    }

    .user__name {
        display: block;
        position: relative;
        font-weight: normal;
        bottom: 0;
    }

    .user__remove {
        visibility: hidden;
        display: flex;
        justify-content: flex-end;

        svg {
            &:hover {
                opacity: 0.6;
                color: #f00;
            }
        }
    }

    .user__name--text {
        @extend .text-shadow;

        &.active {
            color: var(--active-timer-color);
            font-weight: bold;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .user__name--text,
    .user__name--text > input {
        width: 100%;
        text-align: center;
    }

    .shine {
        animation: shine 5s ease-in-out infinite;
        animation-fill-mode: forwards;
        content: '';
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background: rgba(255, 255, 255, 0.13);
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.13) 0%,
            rgba(255, 255, 255, 0.13) 77%,
            rgba(255, 255, 255, 0.5) 92%,
            rgba(255, 255, 255, 0) 100%
        );
    }
}

/* animation */
@keyframes shine {
    10% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    100% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}
