@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500');
@import url('https://fonts.googleapis.com/css?family=Inconsolata');
@import './Global';

.Input {
    $width: 180px;
    $height: 40px;

    * {
        margin: 0;
        padding: 0;
    }

    .input-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    label {
        position: absolute;
        left: 0;
        top: -24px;
        color: var(--header-color);
        @extend .text-shadow;
    }

    .parent {
        width: $width;
        height: $height;
        position: relative;

        .message {
            position: absolute;
        }
    }

    input.message {
        color: var(--header-color);
        border-color: var(--header-color);
        transition: 0.2s;
        @extend .icon-shadow;

        &:focus {
            background: rgba(var(--background-rgba), 0.2);
        }
    }

    .message {
        left: 0;
        width: inherit;
        background: none;
        border: 2px solid;
        font-size: 30px;
        padding-left: 10px;
    }
}
